import React, { Component } from "react"
import Map from "../components/map/map"
import Footer from "../components/footer/footer";
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import Subscribe from "../components/subscribe/subscribe"

class Location extends Component {
  
  render() {
    return (
      <Seo title={'Location'}>
        <Map />
        <Subscribe />
        <Footer />
        <Nav showLogo={true} isDarkBackground={true} />
      </Seo>
    )
  }
}

export default Location;
